import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          border: '10px solid white',
          lineHeight: 1.7,
          padding: 0,
          fontFamily: "'Montserrat', Helvetica, Arial, Lucida, sans-serif",
          fontWeight: 800,
          fontSize: '50px',
          background: 'white',
          color: 'black',
          display: 'inline',
          '&::before': {
            content: '""',
            background: 'white',
          }
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#fff',
    },
    background: {
      default: '#000',
      paper: '#111',
    },
    text: {
      primary: '#fff',
      secondary: '#aaa',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
  },
});
