import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ThemeProvider, CssBaseline } from '@mui/material'
import { theme } from './config/theme'
import MainLayout from './components/Layout/MainLayout'
import Dashboard from './components/Dashboard'
import Xero from './components/Xero'
import XeroCallback from './components/Xero/XeroCallback'
import Sitehost from './components/Sitehost'
import Google from './components/Google'
import Clients from './components/Clients'
import Login from './components/Auth/Login'
import ProtectedRoute from './components/Auth/ProtectedRoute'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/clients" element={<Clients />} />
                    <Route path="/xero/callback" element={<XeroCallback />} />
                    <Route path="/xero/*" element={<Xero />} />
                    <Route path="/sitehost/*" element={<Sitehost />} />
                    <Route path="/google/*" element={<Google />} />
                  </Routes>
                </MainLayout>
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  )
}

export default App