const XERO_CLIENT_ID = import.meta.env.VITE_XERO_CLIENT_ID;
const REDIRECT_URI = import.meta.env.VITE_XERO_REDIRECT_URI;

export const xeroAuth = {
  isAuthenticated(): boolean {
    const token = localStorage.getItem('xero_access_token');
    const tenantId = localStorage.getItem('xero_tenant_id');
    console.log('Checking auth:', { hasToken: !!token, hasTenantId: !!tenantId });
    return !!(token && tenantId);
  },

  async initiateLogin() {
    console.log('Initiating Xero login...');
    return new Promise((resolve, reject) => {
      const popup = window.open(
        `https://login.xero.com/identity/connect/authorize?` +
        `response_type=code` +
        `&client_id=${XERO_CLIENT_ID}` +
        `&redirect_uri=${REDIRECT_URI}` +
        `&scope=openid profile email accounting.contacts.read offline_access` +
        `&state=${Math.random().toString(36).substring(7)}`,
        'xero-auth',
        'width=600,height=600'
      );

      let isProcessing = false;

      // Listen for postMessage from popup
      const messageHandler = async (event: MessageEvent) => {
        if (event.origin === window.location.origin && 
            event.data?.type === 'XERO_CALLBACK' && 
            !isProcessing) {
          isProcessing = true;
          console.log('Received Xero callback message:', event.data);
          
          try {
            await this.handleCallback(event.data.code);
            console.log('Authentication successful');
            window.removeEventListener('message', messageHandler);
            resolve(true);
          } catch (error) {
            console.error('Authentication failed:', error);
            reject(error);
          }
        }
      };

      window.addEventListener('message', messageHandler);

      // Add a check for popup closure
      const checkClosed = setInterval(() => {
        if (popup?.closed && !isProcessing) {
          clearInterval(checkClosed);
          window.removeEventListener('message', messageHandler);
          console.log('Popup closed without completing auth');
          resolve(false);
        }
      }, 500);
    });
  },

  async handleCallback(code: string) {
    console.log('Handling callback...');
    try {
      const formData = new FormData();
      formData.append('code', code);
      formData.append('redirect_uri', REDIRECT_URI);

      console.log('Exchanging code for tokens...');
      const response = await fetch(`${import.meta.env.VITE_API_URL}/connect/token`, {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Token exchange failed:', errorText);
        throw new Error('Failed to exchange code for tokens');
      }

      const tokens = await response.json();
      console.log('Received tokens, saving...');
      this.saveTokens(tokens);

      console.log('Getting tenants...');
      const tenantsResponse = await fetch(`${import.meta.env.VITE_API_URL}/connections`, {
        headers: {
          'Authorization': `Bearer ${tokens.access_token}`
        }
      });

      if (!tenantsResponse.ok) {
        const errorText = await tenantsResponse.text();
        console.error('Tenants fetch failed:', errorText);
        throw new Error('Failed to get tenants');
      }

      const tenants = await tenantsResponse.json();
      if (tenants.length > 0) {
        console.log('Saving tenant ID:', tenants[0].tenantId);
        localStorage.setItem('xero_tenant_id', tenants[0].tenantId);
      }

      return true;
    } catch (error) {
      console.error('Token exchange error:', error);
      throw error;
    }
  },

  saveTokens(tokens: any) {
    console.log('Saving tokens to localStorage');
    localStorage.setItem('xero_access_token', tokens.access_token);
    if (tokens.refresh_token) {
      localStorage.setItem('xero_refresh_token', tokens.refresh_token);
    }
  },

  clearTokens() {
    console.log('Clearing tokens from localStorage');
    localStorage.removeItem('xero_access_token');
    localStorage.removeItem('xero_refresh_token');
    localStorage.removeItem('xero_tenant_id');
  }
};
