const API_URL = import.meta.env.VITE_API_URL;

interface XeroContact {
  ContactID: string;
  Name: string;
  EmailAddress?: string;
  CompanyName?: string;
}

export const xeroApi = {
  async getContacts(page: number = 1, pageSize: number = 10): Promise<{
    contacts: XeroContact[];
    totalCount: number;
  }> {
    const token = localStorage.getItem('xero_access_token');
    const tenantId = localStorage.getItem('xero_tenant_id');

    if (!token || !tenantId) {
      throw new Error('Missing authentication');
    }

    try {
      const response = await fetch(`${API_URL}/api.xro/2.0/Contacts?where=IsCustomer==true`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Xero-Tenant-Id': tenantId
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch contacts');
      }

      const data = await response.json();
      const allContacts = data.Contacts || [];
      
      // Sort contacts by name
      const sortedContacts = allContacts.sort((a: XeroContact, b: XeroContact) => 
        a.Name.toLowerCase().localeCompare(b.Name.toLowerCase())
      );
      
      // Handle pagination
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const paginatedContacts = sortedContacts.slice(startIndex, endIndex);

      return {
        contacts: paginatedContacts,
        totalCount: sortedContacts.length,
      };
    } catch (error) {
      console.error('Error fetching contacts:', error);
      throw error;
    }
  }
};
