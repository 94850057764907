import { Box, Button, Container, Typography } from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';
import { googleAuth } from '../../services/auth/googleAuth';
import { StyledHeading } from '../common/StyledHeading';

const Login = () => {
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 4,
        }}
      >
        <StyledHeading>
          Gray Media Dashboard
        </StyledHeading>
        
        <Typography variant="h6" component="p" sx={{ textAlign: 'center' }}>
          Sign in with your Gray Media account
        </Typography>

        <Button
          variant="contained"
          size="large"
          onClick={() => googleAuth.signIn()}
          startIcon={<GoogleIcon />}
          sx={{
            mt: 2,
            backgroundColor: 'white',
            color: 'black',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
          }}
        >
          Sign in with Google
        </Button>
      </Box>
    </Container>
  );
};

export default Login;
