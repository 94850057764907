import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const StyledHeading = styled(Typography)({
  border: '10px solid white',
  lineHeight: 1.7,
  padding: 0,
  fontFamily: "'Montserrat', Helvetica, Arial, Lucida, sans-serif",
  fontWeight: 800,
  fontSize: '50px',
  background: 'white',
  color: 'black',
  display: 'inline',
  '&::before': {
    content: '""',
    background: 'white',
  }
});
