import { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography, Button } from '@mui/material';
import { ClientsTable } from './ClientsTable';
import { xeroAuth } from '../../services/xero/xeroAuth';
import { xeroApi } from '../../services/xero/xeroApi';
import { StyledHeading } from '../common/StyledHeading';

const Clients = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [contacts, setContacts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchContacts = async () => {
    console.log('Fetching contacts...');
    try {
      setLoading(true);
      setError(null);

      if (!xeroAuth.isAuthenticated()) {
        console.log('Not authenticated with Xero');
        setError('Please connect to Xero to view clients');
        setLoading(false);
        return;
      }

      console.log('Authenticated, getting contacts...');
      const data = await xeroApi.getContacts(page + 1, rowsPerPage);
      console.log('Received contacts:', data);
      setContacts(data.contacts);
      setTotalCount(data.totalCount);
    } catch (err) {
      console.error('Error fetching contacts:', err);
      setError('Failed to load contacts. Please try reconnecting to Xero.');
      xeroAuth.clearTokens();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContacts();
  }, [page, rowsPerPage]);

  const handleXeroConnect = async () => {
    console.log('Starting Xero connection...');
    try {
      const success = await xeroAuth.initiateLogin();
      console.log('Xero login result:', success);
      if (success) {
        console.log('Authentication successful, refreshing contacts...');
        setError(null);
        setLoading(true);
        await fetchContacts();
      }
    } catch (err) {
      console.error('Xero connection error:', err);
      setError('Failed to connect to Xero. Please try again.');
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <StyledHeading>Clients</StyledHeading>
      
      {error ? (
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography color="error">{error}</Typography>
          <Button 
            variant="contained" 
            onClick={handleXeroConnect}
            sx={{ mt: 2 }}
          >
            Connect to Xero
          </Button>
        </Box>
      ) : loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <ClientsTable
          contacts={contacts}
          totalCount={totalCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={(newPage) => setPage(newPage)}
          onRowsPerPageChange={(newRowsPerPage) => {
            setRowsPerPage(newRowsPerPage);
            setPage(0);
          }}
        />
      )}
    </Box>
  );
};

export default Clients;
