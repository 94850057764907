import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';

const XeroCallback = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get('code');
    const state = searchParams.get('state');

    if (code && state) {
      // Send message to opener window
      window.opener?.postMessage({
        type: 'XERO_CALLBACK',
        code,
        state
      }, window.location.origin);
      
      // Delay closing the popup
      setTimeout(() => {
        window.close();
      }, 1000); // Give the message time to be processed
    }
  }, [searchParams]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </Box>
  );
};

export default XeroCallback;
