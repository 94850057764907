import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box, Avatar, Typography, Button, Divider } from '@mui/material';
import { Dashboard as DashboardIcon, Receipt, Storage, Google, Logout, People } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { googleAuth } from '../../services/auth/googleAuth';

const Sidebar = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/' },
    { text: 'Clients', icon: <People />, path: '/clients' },
    { text: 'Xero', icon: <Receipt />, path: '/xero' },
    { text: 'Sitehost', icon: <Storage />, path: '/sitehost' },
    { text: 'Google', icon: <Google />, path: '/google' },
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box',
          backgroundColor: 'black',
          color: 'white',
        },
      }}
    >
      {/* User Profile Section */}
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
          mt: 2
        }}
      >
        <Avatar 
          src={user.picture}
          alt={user.name}
          sx={{ width: 64, height: 64 }}
        />
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {user.name}
        </Typography>
        <Typography 
          variant="body2" 
          sx={{ 
            color: '#E0E0E0',  // Light gray with good contrast
            opacity: 0.9,
            textAlign: 'center',
            wordBreak: 'break-word'
          }}
        >
          {user.email}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={() => googleAuth.signOut()}
          startIcon={<Logout />}
          sx={{
            mt: 1,
            color: 'white',
            borderColor: 'white',
            '&:hover': {
              borderColor: 'white',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
          }}
        >
          Sign Out
        </Button>
      </Box>

      <Divider sx={{ my: 2, backgroundColor: 'gray' }} />

      {/* Navigation Menu */}
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {menuItems.map((item) => (
            <ListItem 
              key={item.text}
              component="div"
              onClick={() => navigate(item.path)}
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                cursor: 'pointer'
              }}
            >
              <ListItemIcon sx={{ color: 'white' }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
