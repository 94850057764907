import { Box, Typography, Container } from '@mui/material'
import { StyledHeading } from '../common/StyledHeading'

const Dashboard = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <StyledHeading>
          Gray Media Dashboard
        </StyledHeading>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" component="p">
            Welcome to your business dashboard
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

export default Dashboard